import { User } from '@prisma/client';
import axios from 'axios';
import { UserFromDecodedIdToken } from '../../services/user';

export async function getUser(): Promise<UserFromDecodedIdToken | null> {
  const response = await axios.get('/api/me');
  return response.data || null;
}

export async function updateUser(user: Partial<User>) {
  const response = await axios.patch('/api/me/profile', user);
  return response.data;
}

export async function getRFPCount(): Promise<Number> {
  const rfpCount = await fetch('/api/rfp/count');
  const { count } = await rfpCount.json();
  return count;
}
