export type UnitSystem = 'metric' | 'imperial';

export type Unit = 'm' | 'km' | 'sqm' | 'kg' | 'ksqm' | 'tons' | 'tsqm';

type UnitKeysType = {
  [key in UnitSystem]: {
    [key in Unit]: string;
  };
};

export const metricUnitKeys: UnitKeysType = {
  metric: {
    m: 'm',
    km: 'km',
    sqm: 'sqm',
    kg: 'kg',
    ksqm: 'kg_per_sqm',
    tons: 'tons',
    tsqm: 'tons_per_sqm',
  },
  imperial: {
    m: 'ft',
    km: 'mi',
    sqm: 'sqft',
    kg: 'lbs',
    ksqm: 'lbs_per_sqft',
    tons: 'short_tons',
    tsqm: 'short_tons_per_sqft',
  },
} as any;

export const convertValueToImperial = (value: any, unit: Unit) => {
  if (typeof value !== 'number' || !metricUnitKeys.imperial[unit]) {
    return value;
  }
  switch (unit) {
    case 'm':
      return convertMetresToFeet(value);
    case 'sqm':
      return convertSqmToSqft(value);
    case 'km':
      return convertKmToMiles(value);
    case 'kg':
    case 'ksqm':
      return convertKiloToPounds(value);
    case 'tons':
    case 'tsqm':
      return convertTonsToShortTons(value);
    default:
      return value;
  }
};

export const convertValueToMetric = (value: any, unit: Unit) => {
  if (typeof value !== 'number' || !metricUnitKeys.metric[unit]) {
    return value;
  }
  switch (unit) {
    case 'm':
      return convertFeetToMetres(value);
    case 'sqm':
      return convertSqftToSqm(value);
    case 'km':
      return convertMilesToKm(value);
    case 'kg':
    case 'ksqm':
      return convertPoundsToKilo(value);
    case 'tons':
    case 'tsqm':
      return convertShortTonsToTons(value);
    default:
      return value;
  }
};

export function convertSqmToSqft(meters: number): number {
  return meters * 10.764;
}

export function convertSqftToSqm(feet: number): number {
  return feet / 10.764;
}

export function convertMetresToFeet(metres: number): number {
  return metres * 3.28084;
}

export function convertFeetToMetres(feet: number): number {
  return feet / 3.28084;
}

export function convertKmToMiles(km: number): number {
  return km * 0.621371;
}

export function convertMilesToKm(miles: number): number {
  return miles / 0.621371;
}

export function convertKiloToPounds(kilo: number): number {
  return kilo * 2.20462262185;
}

export function convertPoundsToKilo(pounds: number): number {
  return pounds / 2.20462262185;
}

export function convertTonsToShortTons(tons: number): number {
  return tons * 1.10231;
}

export function convertShortTonsToTons(shortTons: number): number {
  return shortTons / 1.10231;
}
