import { ContactCard, Review } from '../../graphql/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

// declaring the types for our state
export type ServiceProviderState = {
  data: any;
};

const initialState: ServiceProviderState = {
  data: {},
};

export const serviceProviderSlice = createSlice({
  name: 'serviceProvider',
  initialState,
  reducers: {
    setServiceProvider: (state, action: PayloadAction<any>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },

    updateServiceProviderState: (state, action: PayloadAction<any>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },

    updateServiceProviderPropState: (state, action: PayloadAction<{}>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },

    updateServiceProviderContactDetails: (state, action: PayloadAction<{}>) => {
      state.data.contact_details = {
        ...state.data.contact_details,
        ...action.payload,
      };
    },

    updateServiceProviderLocation: (state, action: PayloadAction<any>) => {
      const { id, address } = action.payload;
      const { country, city } = address || {};
      state.data.country = country;
      state.data.city = city;
      state.data.location = { ...state.data.location, ...action.payload };
    },

    createServiceProviderCategory: (state, action: PayloadAction<any>) => {
      state.data.categories = [
        ...(state.data.categories || []),
        action.payload,
      ];
    },

    updateServiceProviderCategory: (state, action: PayloadAction<any>) => {
      state.data.categories = (state.data.categories || []).map((obj: any) =>
        obj.id === action.payload.id ? action.payload : obj
      );
    },

    deleteServiceProviderCategory: (state, action: PayloadAction<any>) => {
      state.data.categories = (state.data.categories || []).filter(
        (obj: any) => obj.id !== action.payload.id
      );
    },

    createServiceProviderCity: (state, action: PayloadAction<any>) => {
      state.data.cities = [...(state.data.cities || []), action.payload];
    },

    deleteServiceProviderCity: (state, action: PayloadAction<any>) => {
      state.data.cities = [
        ...(state.data.cities || []).filter(
          (obj: any) => obj.id !== action.payload
        ),
      ];
    },

    createServiceProviderContact: (
      state,
      action: PayloadAction<ContactCard>
    ) => {
      const { sales_contact_service_provider_id } = action.payload;
      !!sales_contact_service_provider_id
        ? (state.data.sales_contacts = [
            ...(state.data.sales_contacts || []),
            action.payload,
          ])
        : (state.data.management_contacts = [
            ...(state.data.management_contacts || []),
            action.payload,
          ]);
    },

    updateServiceProviderContact: (
      state,
      action: PayloadAction<ContactCard>
    ) => {
      const {
        id,
        sales_contact_service_provider_id,
        management_contact_service_provider_id,
      } = action.payload;

      const salesContact = !!sales_contact_service_provider_id
        ? action.payload
        : null;
      state.data.sales_contacts = [
        ...(state.data.sales_contacts || []).filter(
          (obj: any) => obj.id !== id
        ),
        salesContact,
      ].filter((exists: any) => !!exists);

      const managementContact = !!management_contact_service_provider_id
        ? action.payload
        : null;
      state.data.management_contacts = [
        ...(state.data.management_contacts || []).filter(
          (obj: any) => obj.id !== id
        ),
        managementContact,
      ].filter((exists: any) => !!exists);
    },

    deleteServiceProviderContact: (
      state,
      action: PayloadAction<ContactCard>
    ) => {
      state.data.sales_contacts = [
        ...(state.data.sales_contacts || []).filter(
          (obj: any) => obj.id !== action.payload.id
        ),
      ];
      state.data.management_contacts = [
        ...(state.data.management_contacts || []).filter(
          (obj: any) => obj.id !== action.payload.id
        ),
      ];
    },
    createServiceProviderReview: (state, action: PayloadAction<Review>) => {
      state.data.reviews = [...(state.data.reviews || []), action.payload];
    },
    updateServiceProviderReview: (state, action: PayloadAction<any>) => {
      state.data.reviews = (state.data.reviews || []).map((obj: any) =>
        obj.id === action.payload.id ? action.payload : obj
      );
    },
    createServiceProviderFile: (state, action: PayloadAction<any>) => {
      state.data.files = [...(state.data.files || []), action.payload];
    },

    updateServiceProviderFile: (state, action: PayloadAction<any>) => {
      state.data.files = (state.data.files || []).map((obj: any) =>
        obj.id === action.payload.id ? action.payload : obj
      );
    },
    deleteServiceProviderFile: (state, action: PayloadAction<any>) => {
      state.data.files = [
        ...(state.data.files || []).filter(
          (obj: any) => obj.id !== action.payload
        ),
      ];
    },
  },
});

export const {
  setServiceProvider,
  updateServiceProviderState,
  updateServiceProviderPropState,
  updateServiceProviderLocation,
  updateServiceProviderContactDetails,
  createServiceProviderCategory,
  updateServiceProviderCategory,
  deleteServiceProviderCategory,
  createServiceProviderCity,
  deleteServiceProviderCity,
  createServiceProviderFile,
  updateServiceProviderFile,
  deleteServiceProviderFile,
  createServiceProviderReview,
  updateServiceProviderReview,
  createServiceProviderContact,
  updateServiceProviderContact,
  deleteServiceProviderContact,
} = serviceProviderSlice.actions;

export const getServiceProviderState = (state: RootState) =>
  state.serviceProvider.data;

export default serviceProviderSlice.reducer;
