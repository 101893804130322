import { ContactCard } from '../../graphql/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

// declaring the types for our state
export type ItemState = {
  data: any;
};

const initialState: ItemState = {
  data: {},
};

export const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    setItem: (state, action: PayloadAction<any>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },

    updateItemState: (state, action: PayloadAction<any>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },

    updateItemPropState: (state, action: PayloadAction<{}>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },

    updateItemContactDetails: (state, action: PayloadAction<{}>) => {
      state.data.contact_details = {
        ...state.data.contact_details,
        ...action.payload,
      };
    },

    updateItemLocation: (state, action: PayloadAction<any>) => {
      const { address } = action.payload;
      const { country, city } = address || {};
      state.data.country = country;
      state.data.city = city;
      state.data.location = { ...state.data.location, ...action.payload };
    },

    createItemCategoryState: (state, action: PayloadAction<any>) => {
      state.data.categories = [
        ...(state.data.categories || []),
        action.payload,
      ];
    },

    updateItemCategoryState: (state, action: PayloadAction<any>) => {
      state.data.categories = (state.data.categories || []).map((obj: any) =>
        obj.id === action.payload.id ? action.payload : obj
      );
    },

    deleteItemCategoryState: (state, action: PayloadAction<any>) => {
      state.data.categories = (state.data.categories || []).filter(
        (obj: any) => obj.id !== action.payload.id
      );
    },

    createItemCity: (state, action: PayloadAction<any>) => {
      state.data.cities = [...(state.data.cities || []), action.payload];
    },

    deleteItemCity: (state, action: PayloadAction<any>) => {
      state.data.cities = [
        ...(state.data.cities || []).filter(
          (obj: any) => obj.id !== action.payload
        ),
      ];
    },

    createItemContact: (state, action: PayloadAction<ContactCard>) => {
      const { sales_contact_service_provider_id } = action.payload;
      !!sales_contact_service_provider_id
        ? (state.data.sales_contacts = [
            ...(state.data.sales_contacts || []),
            action.payload,
          ])
        : (state.data.management_contacts = [
            ...(state.data.management_contacts || []),
            action.payload,
          ]);
    },

    updateItemContact: (state, action: PayloadAction<ContactCard>) => {
      const {
        id,
        sales_contact_service_provider_id,
        management_contact_service_provider_id,
      } = action.payload;

      const salesContact = !!sales_contact_service_provider_id
        ? action.payload
        : null;
      state.data.sales_contacts = [
        ...(state.data.sales_contacts || []).filter(
          (obj: any) => obj.id !== id
        ),
        salesContact,
      ].filter((exists: any) => !!exists);

      const managementContact = !!management_contact_service_provider_id
        ? action.payload
        : null;
      state.data.management_contacts = [
        ...(state.data.management_contacts || []).filter(
          (obj: any) => obj.id !== id
        ),
        managementContact,
      ].filter((exists: any) => !!exists);
    },

    deleteItemContact: (state, action: PayloadAction<ContactCard>) => {
      state.data.sales_contacts = [
        ...(state.data.sales_contacts || []).filter(
          (obj: any) => obj.id !== action.payload.id
        ),
      ];
      state.data.management_contacts = [
        ...(state.data.management_contacts || []).filter(
          (obj: any) => obj.id !== action.payload.id
        ),
      ];
    },

    createItemFile: (state, action: PayloadAction<any>) => {
      state.data.files = [...(state.data.files || []), action.payload];
    },

    updateItemFile: (state, action: PayloadAction<any>) => {
      state.data.files = (state.data.files || []).map((obj: any) =>
        obj.id === action.payload.id ? action.payload : obj
      );
    },

    deleteItemFile: (state, action: PayloadAction<any>) => {
      state.data.files = [
        ...(state.data.files || []).filter(
          (obj: any) => obj.id !== action.payload
        ),
      ];
    },
  },
});

export const {
  setItem,
  updateItemState,
  updateItemPropState,
  updateItemLocation,
  updateItemContactDetails,
  createItemCity,
  deleteItemCity,
  createItemFile,
  updateItemFile,
  deleteItemFile,
  createItemContact,
  updateItemContact,
  deleteItemContact,
} = itemSlice.actions;

export const getItemState = (state: RootState) => state.item.data;

export default itemSlice.reducer;
