import { ApolloClient, InMemoryCache } from "@apollo/client";

export const client = new ApolloClient({
  uri:
    process.env.GRAPHQL_API ||
    process.env.NEXT_PUBLIC_GRAPHQL_API ||
    "http://localhost:4000/graphql",
  cache: new InMemoryCache(),
  ssrMode: typeof window === "undefined",
});
