export const getHeap = () => {
  if (typeof window !== "undefined" && "heap" in window) {
    return (window as any).heap;
  }
  return null;
};

export const trackEvent = (event: any) => {
  const heap = getHeap();
  if (!heap) {
    return;
  }
  heap.track(event);
};

export const identify = (userId: string, properties: any) => {
  const heap = getHeap();
  if (!heap) {
    return;
  }
  heap.identify(userId);
  heap.addUserProperties(properties);
};
