import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  User,
} from '@firebase/auth';
import axios from 'axios';

export async function sendSignInLink(
  email: string,
  returnPath?: string
): Promise<string> {
  localStorage.setItem('signin_email', email);
  const result = await axios.post('/api/auth/login', {
    email,
  });

  return result.data.id;
}

export async function confirmSignInLink(
  id: string,
  code: string,
  returnPath?: string
) {
  const returnTo = returnPath || window.location.origin + '/my-businesses';

  const result = await axios.post('/api/auth/login', {
    id,
    code,
    url: returnTo,
  });

  if (result.data.result) {
    window.location = result.data.result;
  }
}

export async function handleSignInCallback() {
  const auth = getAuth();

  if (!auth) {
    return;
  }

  const isSignInLink = isSignInWithEmailLink(auth, window.location.href);
  const email = localStorage.getItem('signin_email');
  if (!isSignInLink && !email) {
    return;
  }

  if (!email) {
    throw new Error(
      'Please use the link on the same browser you requested the link from.'
    );
  }

  try {
    const result = await signInWithEmailLink(auth, email, window.location.href);

    return result.user;
  } catch (err: any) {
    throw err.code;
  } finally {
    localStorage.removeItem('signin_email');
  }
}

let userPromise: Promise<User | null> | undefined;

export function getIdentity() {
  if (userPromise) {
    return userPromise;
  }

  const auth = getAuth();
  if (!auth) {
    return Promise.resolve(null);
  }

  userPromise = awaitUserLoggedIn();

  return userPromise;
}

export async function createSession(user: User) {
  const idToken = await user.getIdToken(true);
  await axios.post('/api/auth/session', {
    csrfToken: (window as any).csrfToken,
    idToken,
  });
}

export function awaitUserLoggedIn() {
  return new Promise<User | null>(async (resolve) => {
    const auth = getAuth();
    if (!auth) {
      return resolve(null);
    }

    await handleSignInCallback();

    const user = await auth.currentUser;

    if (user) {
      await createSession(user);
      return resolve(user);
    }

    resolve(null);
  });
}

export async function deleteSession() {
  await axios.post('/api/auth/logout');
}
