import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { isDev } from '../lib/utils';

interface AppProviderProps {
  children: ReactNode;
}

interface AppContext {
  devMode: Boolean;
}

const appContext = createContext<AppContext>({
  devMode: false,
});

export function AppProvider({ children }: AppProviderProps) {
  const [devMode, setDevMode] = useState<Boolean>(false);

  useEffect(() => {
    const devMode = isDev();
    // console.log('devMode', isDev, !!location, typeof window);
    setDevMode(devMode);
  }, []);

  const props: AppContext = {
    devMode,
  };

  return <appContext.Provider value={props}>{children}</appContext.Provider>;
}

export function useApp() {
  return useContext(appContext);
}
