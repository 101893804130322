/* eslint-disable @next/next/next-script-for-ga */
import Head from 'next/head';
import Script from 'next/script';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';
import { store } from '../app/store';
import { client } from '../lib/graphql';
import '../lib/i18n';
import '../lib/apis/firebase';
import '../styles/index.scss';
import { AuthUserProvider } from '../context/AuthUserContext';
import { AppProvider } from '../context/AppContext';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/favicons/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <link rel="shortcut icon" href="/assets/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
        <meta name="xcsrf-token" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-2DHG5QZ6RG"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-2DHG5QZ6RG');
`,
          }}
        ></script>
      </Head>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <AuthUserProvider>
            <AppProvider>
              <>
                <Component {...pageProps} />
                <Script
                  id="hs-script-loader"
                  async
                  defer
                  src="//js.hs-scripts.com/20844017.js"
                />
                <Script
                  id="heap"
                  dangerouslySetInnerHTML={{
                    __html: `
                  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                  heap.load("2453818949");
                `,
                  }}
                />
              </>
            </AppProvider>
          </AuthUserProvider>
        </ApolloProvider>
      </Provider>
    </>
  );
}

export default appWithTranslation(MyApp);
