import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

// declaring the types for our state
export type SearchState = {
  values: {};
  query: {};
  locations: any[];
};

const initialState: SearchState = {
  values: {},
  locations: [],
  query: {
    types: "venues",
  },
} as SearchState;

export const searchSlice = createSlice({
  name: "search",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setSearchValues: (state, action: PayloadAction<any>) => {
      state.values = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<any>) => {
      state.query = action.payload;
    },
    setLocations: (state, action: PayloadAction<any>) => {
      state.locations = action.payload;
    },
  },
});

// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setSearchValues, setSearchQuery, setLocations } =
  searchSlice.actions;

// calling the above actions would be useless if we could not access the data in the state. So, we use something called a selector which allows us to select a value from the state.
export const searchValues = (state: RootState) => state.search.values;
export const searchQuery = (state: RootState) => state.search.query;
export const searchLocations = (state: RootState) => state.search.locations;
// exporting the reducer here, as we need to add this to the store
export default searchSlice.reducer;
