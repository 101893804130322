import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

export type MetricsState = {
  name: 'metric' | 'imperial';
  metric: boolean;
  imperial: boolean;
};

const initialState: MetricsState = {
  name: 'metric',
  metric: true,
  imperial: false,
} as MetricsState;

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    setMetric: (state) => {
      state.name = 'metric';
      state.metric = true;
      state.imperial = false;
    },
    setImperial: (state) => {
      state.name = 'imperial';
      state.metric = false;
      state.imperial = true;
    },
  },
});

export const { setMetric, setImperial } = metricsSlice.actions;
export const metricsName = (state: RootState) => state.metrics.name;
export const isMetric = (state: RootState) => state.metrics.metric;
export const isImperial = (state: RootState) => state.metrics.imperial;

export default metricsSlice.reducer;
