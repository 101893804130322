import { CollectionResponsePublicAuditLog } from '@hubspot/api-client/lib/codegen/cms/audit_logs';
import { convertValueToMetric, Unit } from './units';

export const isDev = (host?: string) => {
  const location =
    host || (typeof window !== 'undefined' && window.location.hostname) || '';
  const domain = location.split('.').slice(-2).join('.');
  return !!(domain !== 'venuescout.org');
};

/*
 * Device detecion
 */
export const isTouchDevice = () => {
  return false;
  // (
  //   window.matchMedia("(pointer: coarse)").matches
  //   || 'ontouchstart' in window
  //   || !!(navigator.maxTouchPoints)
  // );
};

/*
 * Random values
 */
export const randomFloat = (min: number, max: number, decs: number) => {
  const rand = Math.random() * (max - min) + min;
  const dec = Math.pow(10, decs);
  return Math.floor(rand * dec) / dec;
};

export const randomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const arraySample = (arr: any[]) =>
  arr[Math.floor(Math.random() * arr.length)];

/*
 * Sorting
 */
export const byProperty = (
  propertyName: string,
  order = 'asc',
  caseSensivity = false
) => {
  return (a: any, b: any) => {
    const numeric = typeof a[propertyName] === 'number';
    const aProp =
      caseSensivity || numeric
        ? a[propertyName]
        : (a[propertyName] || 'A').toString().toLowerCase();
    const bProp =
      caseSensivity || numeric
        ? b[propertyName]
        : (b[propertyName] || 'B').toString().toLowerCase();
    switch (order.toLowerCase()) {
      case 'desc':
        if (aProp > bProp) return -1;
        if (aProp < bProp) return 1;
        return 0;
      default:
        if (aProp > bProp) return 1;
        if (aProp < bProp) return -1;
        return 0;
    }
  };
};

/*
 * DOM Elements
 */
export const getParent = (className: string) => {
  const target = document.getElementsByClassName(className)[0] as HTMLElement;
  // const target = className ? document.getElementsByClassName(className)[0] : document.documentElement;
  return target.parentElement;
};

export const getYOffset = (className: string) => {
  const target = document.getElementsByClassName(className)[0] as HTMLElement;
  if (target) {
    const offset = target.getBoundingClientRect();
    const scrollTop = offset.top;
    return scrollTop;
  } else {
    return window.pageYOffset; // || target.scrollTop;
  }
};

/*
 * Scrolling
 */
export const scrollToElement = (className: string, offset: number = 0) => {
  const target = document.getElementsByClassName(className)[0] as HTMLElement;
  if (target) {
    target.scrollIntoView();
  }
};

export const getScrollTop = (e: any) => {
  const scrollTop1 = e.pageY ? e.pageY : 0; // FF
  const scrollTop2 =
    e.srcElement && e.srcElement.body ? e.srcElement.body.scrollTop : 0; // Chrome
  const scrollTop3 =
    e.srcElement && e.srcElement.documentElement
      ? e.srcElement.documentElement.scrollTop
      : 0; // IE
  return scrollTop1 + scrollTop2 + scrollTop3;
};

export const isScrolled = () => {
  const body = document.getElementsByTagName('body')[0];
  const bodyClass = body.getAttribute('class');
  return (bodyClass || '').indexOf('scrolled') > -1;
};

export const toggleScroll = (scroll: boolean) => {
  const root = document.getElementsByTagName('html')[0];
  if (scroll) {
    root.classList.remove('noscroll');
  } else {
    root.classList.add('noscroll');
  }
};

/*
 * Object converter
 */
// Merge nested objects avoiding nNUL values
export const mergeObjects = (dst: any, src: any) => {
  Object.keys(src).forEach((key) => {
    if (!dst[key]) {
      dst[key] = src[key];
    } else if (
      typeof src[key] === 'object' &&
      src[key] !== null &&
      typeof dst[key] === 'object' &&
      dst[key] !== null
    ) {
      mergeObjects(dst[key], src[key]);
    }
  });
};
// Remove NULL values
export const clearObj = (obj: any) => {
  const cleared = { ...obj };
  Object.keys(cleared).forEach((el: any) => {
    if (!cleared[el]) {
      delete cleared[el];
    }
  });
  return cleared;
};

/*
 * String converter
 */
export const toAddressStr = (obj: any, short?: boolean) => {
  const {
    address_line1,
    address_line2,
    formatted,
    county,
    district,
    country,
    state,
    city,
  } = obj;
  const currState = state !== city ? state : '';
  const str = !!country
    ? short
      ? `${city || district}, ${country}`
      : formatted ||
        `${country}, ${!!currState ? currState + ',' : ''} ${city || district}`
    : short
    ? address_line1
    : formatted || '';
  return str;
};

export const toAddressLine = (strs: string[]) => {
  const str = strs.filter((el) => !!el).join(', ');
  return str;
};

export const toAddressBlock = (obj: any) => {
  const {
    formatted,
    street,
    housenumber,
    postcode,
    country,
    district,
    county,
    state,
    city,
  } = obj;
  const streetStr = !!street ? `${street}` : '';
  const countryStr = !!country ? `${country}` : '';
  const countyStr = !!county && county !== state ? `${county}` : '';
  const stateStr = !!state && state !== city ? `${state}` : '';
  const streetLine = formatted
    ? formatted.split(',')[0]
    : `${streetStr} ${housenumber || ''}`;
  const addressLine = '';
  const postcodeStr = !!postcode ? `${postcode}` : '';
  const cityStr = !!city ? `${city}` : !!district ? `${district}` : '';

  const lines = [streetLine];
  if (postcodeStr || cityStr) {
    lines.push(toAddressLine([postcodeStr, cityStr]));
  }
  if (countryStr || countyStr || stateStr) {
    lines.push(toAddressLine([countryStr, countyStr, stateStr]));
  }
  return (
    addressLine ||
    lines.map((line, i) => (
      <span key={i}>
        {line}
        {i !== lines.length - 1 && <br />}
      </span>
    ))
  );
};

export const toLocationStr = (obj: any, short?: boolean) => {
  if (!obj) return '';
  const { county, district, country, state, city } = obj;
  const countryStr = !!country ? `${country}` : '';
  const countyStr = !!county && county !== state ? `, ${county}` : '';
  const stateStr = !!state && state !== city ? `, ${state}` : '';
  const cityStr = !!city ? `, ${city}` : '';
  const shortCityStr = !!city ? `${city},` : '';
  const districtStr = !!district ? ` - ${district}` : '';
  const locationLine = !!country
    ? `${countryStr}${countyStr}${stateStr}${cityStr}${districtStr}`
    : '';
  const locationStr = short ? `${shortCityStr} ${countryStr}` : locationLine;
  return locationStr.trim();
};

export const toDateRangeStr = (...dates: (string | null)[]) => {
  return dates
    .filter(Boolean)
    .map((str) => str?.replace(/-/g, '.'))
    .join(' - ');
};

export const toDateTimeStr = (dateStr: string = '') => {
  return `${dateStr.replace(/-/g, '/').replace(/ /g, ' - ')}`;
};

export const relativeDays = (timestamp: any, lang: string = 'en') => {
  if (!timestamp) {
    return '';
  }
  const now = new Date();
  const monthNow = now.getMonth() + 1;
  const monthThen = new Date(timestamp).getMonth() + 1;
  const yearNow = now.getFullYear();
  const yearThen = new Date(timestamp).getFullYear();
  const ts =
    typeof timestamp === 'string' ? new Date(timestamp).getTime() : timestamp;
  const rtf = new Intl.RelativeTimeFormat(lang, {
    numeric: 'auto',
  });
  const oneDayInMs = 1000 * 60 * 60 * 24;
  const daysDifference = Math.round((ts - new Date().getTime()) / oneDayInMs);
  const weeksDifference = Math.round(
    (ts - new Date().getTime()) / (oneDayInMs * 7)
  );
  const monthsDifference = yearNow === yearThen ? monthThen - monthNow : 0;
  const yearsDifference = yearThen - yearNow;
  const period =
    yearsDifference <= -1
      ? 'year'
      : monthsDifference <= -1
      ? 'month'
      : weeksDifference <= -1
      ? 'week'
      : 'day';
  switch (period) {
    case 'year':
      return rtf.format(yearsDifference, period);
    case 'month':
      return rtf.format(monthsDifference, period);
    case 'week':
      return rtf.format(weeksDifference, period);
    default:
      return rtf.format(daysDifference, period);
  }
};

export const toClass = (obj: any, delimiter = '_', maxChars = 30) => {
  if (!!!obj) {
    return '';
  } else {
    const str = Array.isArray(obj) ? obj.join(delimiter) : obj;
    let newStr = str
      .toLowerCase()
      .replace(/ *\([^)]*\) */g, '') // remove brackets incl. content
      .replace(/\n|[^a-z/_|+ -:.]+/g, '') // remove all chars except a-z/_|+ -:.
      .replace(/[/_|+ -:.]+/g, delimiter) // replace /_|+ -:. with [delimiter]
      .trim()
      .substr(0, maxChars)
      .trim(); // remove leading/trailing whitespace
    newStr =
      newStr.substr(-1, 1) === delimiter
        ? newStr.substr(0, newStr.length - 1)
        : newStr; // remove trailing [delimiter]
    newStr =
      newStr.substr(0, 1) === delimiter
        ? newStr.substr(1, newStr.length - 1)
        : newStr; // remove leading [delimiter]
    return newStr;
  }
};

/*
 * Form Validation
 */

export const convertValue = (
  value: any,
  format: string,
  convertUnit?: Unit
) => {
  if (format !== 'boolean' && !value) {
    return null;
  }
  const str = String(value);
  switch (format) {
    case 'int':
    case 'float':
    case 'number':
    case 'lat':
    case 'lon':
    case 'lng':
      const num = parseFloat(str);
      return !!convertUnit ? convertValueToMetric(num, convertUnit) : num;
    case 'phone':
      return autoCorrect({ value: str, dataset: { format } }, 0);
    case 'boolean':
      return ['', '-', 'undefined', 'null'].indexOf(str) > -1
        ? null
        : ['true', 'Yes', 'yes'].indexOf(str) > -1;
    default:
      return str;
  }
};

export const getErrorMsg = (errorObj: any) => {
  const { code, message, response } = errorObj || {};
  const { data, status } = response || {};
  const error = data || message || code || status;
  console.log('Error', errorObj, response, error);
  return typeof error === 'string'
    ? error
    : code
    ? code.split('/')[1]
    : message || error;
};

export const getFormData = (id: string, asFields?: boolean) => {
  const form = document.getElementById(id) as HTMLElement;
  if (!form) {
    return {};
  }
  const inputs = form.querySelectorAll(
    'input, textarea, select, hidden'
  ) as any;
  const fields = Array.from(inputs).map((el: any, i: number) => {
    const { name, value, type, checked } = el;
    const format = el.getAttribute('data-format');
    const imperialUnit = el.getAttribute('data-imperial');
    const val =
      type === 'checkbox' ? checked : convertValue(value, format, imperialUnit);
    // console.log('getFormData', name, format, type, checked);
    const obj = { [name]: val };
    return obj;
  });
  const obj = fields.reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {});
  return asFields ? fields : obj;
};

export const autoCorrect = (field: any, max: number) => {
  if (!field) {
    return '';
  }
  const { value, dataset } = field as any;
  const { format } = dataset;
  const maxStr = !!max ? max.toString() : value;
  switch (format) {
    case 'int':
    case 'float':
    case 'number':
      return !!max && parseInt(value) > max
        ? maxStr.substr(0, 6)
        : value.replace(/[^0-9.\-]/g, '');
    case 'phone':
      return '+' + value.replace(/[^0-9\ ]/g, '');
    default:
      return max ? value.substr(0, max) : value;
  }
};

export const getFieldError = (field: any, val: any = '') => {
  if (!field || !!field.files) {
    return false;
  }
  /**
   * TODO: validate file fields
   */
  const { name, type, value, tagName, required, dataset, files } = field as any;

  const { format, checked, choice } = dataset;
  const currValue = (val || value || '').trim();
  const currSelector =
    tagName === 'TEXTAREA'
      ? tagName
      : format || (type === 'text' ? name : type || name);
  let regex = /^.{1,}$/;
  // console.log(name, type, currValue);
  if (
    required ||
    name === 'password' ||
    (type !== 'checkbox' &&
      type !== 'file' &&
      format !== 'boolean' &&
      currValue.length > 0)
  ) {
    switch (currSelector) {
      case 'int':
        regex = /^[0-9]{1,}$/;
        return !regex.test(currValue);
      case 'float':
        regex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
        return !regex.test(currValue);
      case 'radio':
      case 'boolean':
      case 'checkbox':
        const fieldChecked = document.querySelector(
          'input[name="' + name + '"]:checked'
        );
        const dataChecked = !['undefined', 'false', 'null', ''].some(
          (el) => el === String(checked)
        );
        const unchecked =
          value === undefined || (!dataChecked && !fieldChecked);
        // console.log(name, unchecked, typeof checked);
        return unchecked;
      case 'tel':
      case 'phone':
        regex = /^\+(?:[0-9] ?){6,16}[0-9]$/;
        return !regex.test(currValue);
      case 'zip':
      case 'plz':
      case 'zip-code':
      case 'zipcode':
      case 'postcode':
      case 'post-code':
      case 'postal-code':
      case 'postalcode':
        regex = /^[a-zA-Z0-9 -]{2,10}$/;
        return !regex.test(currValue);
      //regex = /^.{4,10}$/s; // single llne: /^[\s\S]{4,138}$/'
      case 'email':
        regex = /^[a-zA-Z0-9-+.]+@[a-zA-Z0-9-.]+\.[A-Za-z]+$/;
        return !regex.test(currValue);
      case 'password':
        // more than 8 chars
        // at least one number
        // at least one special character // !@$%^&*(){}:;<>,.?/~_+-=|
        regex = required
          ? /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/
          : regex;
        return !regex.test(currValue);
      case 'TEXTAREA':
      case 'textarea':
        regex = /\S/;
        return !regex.test(currValue);
      case 'file':
        return !currValue || !currValue.length;
      // case "select":
      //   //if(type==="select")console.log(field, value)
      case 'date':
        regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
        return !regex.test(currValue);
      default:
        return !regex.test(currValue);
    }
  } else {
    return false;
  }
};

export const getDateStr = (
  from: string, // 2022-12-31
  addDays: number = 0
) => {
  const now = new Date();
  const fromYears = parseInt(
    from?.split('-')[0] || now.getFullYear().toString()
  );
  const fromMonths = parseInt(
    from?.split('-')[1] || (now.getMonth() + 1).toString()
  );
  const fromDays = parseInt(from?.split('-')[2] || now.getDate().toString());
  const date = new Date(fromYears, fromMonths, fromDays + addDays);
  const d = date.getDate();
  const m = date.getMonth();
  const yyyy = date.getFullYear();
  const dd = d < 10 ? '0' + d : d;
  const mm = m < 10 ? '0' + m : m;
  return yyyy + '-' + mm + '-' + dd;
};

export const getFieldErrors = (id: string) => {
  const form = document.getElementById(id) as HTMLElement;
  const inputs = form.querySelectorAll('input, textarea, select') as any;
  const fields = Array.from(inputs) || [];
  return fields
    .filter((field: any) => !form || getFieldError(field))
    .map((field: any) => field.name);
};

export const similarity = (a: string, b: string) => {
  const editDistance = (s1: string, s2: string) => {
    const costs = new Array();
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j;
        else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1)) {
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            }
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  };

  let longer = a;
  let shorter = b;
  if (a.length < b.length) {
    longer = b;
    shorter = a;
  }
  const longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }

  const s1 = shorter.toLowerCase();
  const s2 = longer.toLowerCase();

  if (s2.indexOf(s1) !== -1) {
    return 1.0;
  }

  return (longerLength - editDistance(s1, s2)) / longerLength;
};

export const classNames = (...args: (string | null | undefined)[]) => {
  const classes: string[] = [];
  args.forEach((arg) => {
    if (arg) {
      classes.push(arg);
    }
  });
  return classes.join(' ');
};

export const removeDiacritics = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const serializePrismaObject = (obj: any) => {
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    if (obj[key] instanceof Date) {
      obj[key] = obj[key].toISOString();
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      obj[key] = serializePrismaObject(obj[key]);
    }
  });
  return obj;
};
