import { User as Identity, getAuth } from '@firebase/auth';
import { User } from '@prisma/client';
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { identify } from '../lib/analytics';
import * as authLib from '../lib/apis/auth';
import * as userLib from '../lib/apis/user';
import { UserFromDecodedIdToken } from '../services/user';

interface AuthUserProviderProps {
  children: ReactNode;
  user?: UserFromDecodedIdToken | null;
}

interface AuthUserContext {
  authLoading: Boolean;
  user: UserFromDecodedIdToken | null;
  identity: Identity | null;
  rfpCount: Number | null;
  logOut: () => Promise<void>;
  updateUser: (user: Partial<User>) => Promise<UserFromDecodedIdToken>;
  getUser: () => Promise<void>;
  getRFPCount: (count: Number | null) => Promise<Number | null>;
}

const authUserContext = createContext<AuthUserContext>({
  authLoading: true,
  user: null,
  identity: null,
  rfpCount: null,
  logOut: () => Promise.resolve(),
  updateUser: (_) => Promise.resolve({} as UserFromDecodedIdToken),
  getUser: () => Promise.resolve(),
  getRFPCount: (count) => Promise.resolve(count),
});

export function AuthUserProvider({
  children,
  user: userFromProps = null,
}: AuthUserProviderProps) {
  const [rfpCount, setRFPCount] = useState<Number | null>(null);
  const [authLoading, setAuthLoading] = useState<Boolean>(true);
  const [identity, setIdentity] = useState<Identity | null>(null);
  const [user, setUser] = useState<UserFromDecodedIdToken | null>(
    userFromProps
  );

  const onAuthStateChanged = useCallback(() => {
    getAuth().onAuthStateChanged(async (identity) => {
      console.log(`on auth change`, identity);
      if (identity) {
        await authLib.createSession(identity);
        setIdentity(identity);
        const nextUser = await userLib.getUser();
        if (nextUser) {
          setUser(nextUser);

          try {
            identify(nextUser.id, {
              email: nextUser.email,
              first_name: nextUser.name,
              last_name: nextUser.family_name,
              company: nextUser.company,
            });
          } catch {}

          const count = await userLib.getRFPCount();
          setRFPCount(count);
        }
        setAuthLoading(false);
      } else {
        setIdentity(null);
        setUser(null);
        setAuthLoading(false);
        setRFPCount(null);
      }
    });
  }, []);

  useEffect(() => {
    authLib.handleSignInCallback();
    onAuthStateChanged();
  }, [onAuthStateChanged]);

  const logOut = useCallback(async () => {
    setAuthLoading(true);
    await getAuth().signOut();
    await authLib.deleteSession();
    setAuthLoading(false);
  }, []);

  const updateUser = useCallback(async (new_user: Partial<User>) => {
    try {
      await userLib.updateUser(new_user);
      const updated_user = await userLib.getUser();
      if (!updated_user) {
        throw new Error('Not signed in');
      }
      setUser(updated_user);
      return updated_user;
    } catch (err: any) {
      throw err;
    }
  }, []);

  const getUser = useCallback(async () => {
    const user = await userLib.getUser();
    if (user) {
      setUser(user);
    }
  }, []);

  const getRFPCount = useCallback(async () => {
    const count = await userLib.getRFPCount();
    return count;
  }, []);

  const props: AuthUserContext = {
    authLoading,
    user,
    identity,
    rfpCount,
    logOut,
    updateUser,
    getUser,
    getRFPCount,
  };

  return (
    <authUserContext.Provider value={props}>
      {children}
    </authUserContext.Provider>
  );
}

export const useAuth = () => useContext(authUserContext);
