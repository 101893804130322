import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import searchReducer from '../features/search/searchSlice';
import itemReducer from '../features/item/itemSlice';
import venueReducer from '../features/venue/venueSlice';
import serviceProviderReducer from '../features/serviceProvider/serviceProviderSlice';
import metricsReducer from '../features/metrics/metricsSlice';

export const store = configureStore({
  reducer: {
    item: itemReducer,
    venue: venueReducer,
    serviceProvider: serviceProviderReducer,
    search: searchReducer,
    metrics: metricsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
