import {
  Venue,
  Review,
  ContactCard,
  VenueSpace,
  Location,
} from '../../graphql/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

// declaring the types for our state
export type VenueState = {
  data: any;
};

const initialState: VenueState = {
  data: {},
};

export const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    setVenue: (state, action: PayloadAction<any>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },

    updateVenueState: (state, action: PayloadAction<any>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },

    updateVenuePropState: (state, action: PayloadAction<{}>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },

    updateVenueContactDetails: (state, action: PayloadAction<{}>) => {
      state.data.contact_details = {
        ...state.data.contact_details,
        ...action.payload,
      };
    },

    createVenueCity: (state, action: PayloadAction<any>) => {
      state.data.cities = [...(state.data.cities || []), action.payload];
    },

    deleteVenueCity: (state, action: PayloadAction<any>) => {
      state.data.cities = [
        ...(state.data.cities || []).filter(
          (obj: any) => obj.id !== action.payload
        ),
      ];
    },

    createVenueSpaceState: (state, action: PayloadAction<VenueSpace>) => {
      state.data.venue_spaces = [
        ...(state.data.venue_spaces || []),
        action.payload,
      ];
    },

    updateVenueSpaceState: (state, action: PayloadAction<VenueSpace>) => {
      state.data.venue_spaces = (state.data.venue_spaces || []).map(
        (obj: any) => (obj.id === action.payload.id ? action.payload : obj)
      );
    },

    deleteVenueSpaceState: (state, action: PayloadAction<VenueSpace>) => {
      state.data.venue_spaces = (state.data.venue_spaces || []).filter(
        (obj: any) => obj.id !== action.payload.id
      );
    },

    updateVenueLocation: (state, action: PayloadAction<any>) => {
      const { id, address } = action.payload;
      const { country, city } = address || {};
      state.data.country = country;
      state.data.city = city;
      state.data.location = { ...state.data.location, ...action.payload };
    },

    createVenueContact: (state, action: PayloadAction<ContactCard>) => {
      const { sales_contact_venue_id } = action.payload;
      !!sales_contact_venue_id
        ? (state.data.sales_contacts = [
            ...(state.data.sales_contacts || []),
            action.payload,
          ])
        : (state.data.management_contacts = [
            ...(state.data.management_contacts || []),
            action.payload,
          ]);
    },

    updateVenueContact: (state, action: PayloadAction<ContactCard>) => {
      const { id, sales_contact_venue_id, management_contact_venue_id } =
        action.payload;

      const salesContact = !!sales_contact_venue_id ? action.payload : null;
      state.data.sales_contacts = [
        ...(state.data.sales_contacts || []).filter(
          (obj: any) => obj.id !== id
        ),
        salesContact,
      ].filter((exists: any) => !!exists);

      const managementContact = !!management_contact_venue_id
        ? action.payload
        : null;
      state.data.management_contacts = [
        ...(state.data.management_contacts || []).filter(
          (obj: any) => obj.id !== id
        ),
        managementContact,
      ].filter((exists: any) => !!exists);
    },

    deleteVenueContact: (state, action: PayloadAction<ContactCard>) => {
      state.data.sales_contacts = [
        ...(state.data.sales_contacts || []).filter(
          (obj: any) => obj.id !== action.payload.id
        ),
      ];
      state.data.management_contacts = [
        ...(state.data.management_contacts || []).filter(
          (obj: any) => obj.id !== action.payload.id
        ),
      ];
    },
    createVenueFile: (state, action: PayloadAction<any>) => {
      state.data.files = [...(state.data.files || []), action.payload];
    },

    updateVenueFile: (state, action: PayloadAction<any>) => {
      state.data.files = (state.data.files || []).map((obj: any) =>
        obj.id === action.payload.id ? action.payload : obj
      );
    },
    deleteVenueFile: (state, action: PayloadAction<any>) => {
      state.data.files = [
        ...(state.data.files || []).filter(
          (obj: any) => obj.id !== action.payload
        ),
      ];
    },
    createVenueReview: (state, action: PayloadAction<Review>) => {
      state.data.reviews = [...(state.data.reviews || []), action.payload];
    },
    updateVenueReview: (state, action: PayloadAction<any>) => {
      state.data.reviews = (state.data.reviews || []).map((obj: any) =>
        obj.id === action.payload.id ? action.payload : obj
      );
    },
    updateServiceProviders: (state, action: PayloadAction<any>) => {
      state.data.service_providers = [...action.payload];
    },
  },
});

export const {
  setVenue,
  updateVenueState,
  updateVenuePropState,
  updateVenueLocation,
  updateVenueContactDetails,
  createVenueCity,
  deleteVenueCity,
  createVenueSpaceState,
  updateVenueSpaceState,
  deleteVenueSpaceState,
  createVenueReview,
  updateVenueReview,
  createVenueFile,
  updateVenueFile,
  deleteVenueFile,
  createVenueContact,
  updateVenueContact,
  deleteVenueContact,
  updateServiceProviders,
} = venueSlice.actions;

export const getVenueState = (state: RootState) => state.venue.data;

export default venueSlice.reducer;
